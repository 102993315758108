// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XyfPGA_vT", "JRVSwvTA1"];

const variantClassNames = {JRVSwvTA1: "framer-v-2lvr01", XyfPGA_vT: "framer-v-12clhh0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], type: "tween"}, JRVSwvTA1: {damping: 11, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "XyfPGA_vT", "Variant 2": "JRVSwvTA1"}

const getProps = ({height, id, tint, width, ...props}) => { return {...props, CyE7CB3gO: tint ?? props.CyE7CB3gO ?? "var(--token-265a51b5-06ea-48da-a42e-3d51fcd0756d, rgb(0, 153, 255)) /* {\"name\":\"Blue\"} */", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XyfPGA_vT"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tint?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CyE7CB3gO, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XyfPGA_vT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear14a54zc = activeVariantCallback(async (...args) => {
await delay(() => setVariant("JRVSwvTA1"), 1000)
})

const onAppear1ffnzzp = activeVariantCallback(async (...args) => {
await delay(() => setVariant("XyfPGA_vT"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear14a54zc, JRVSwvTA1: onAppear1ffnzzp})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-3xtxs", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-12clhh0", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"XyfPGA_vT"} ref={ref} style={{...style}} {...addPropertyOverrides({JRVSwvTA1: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-ihsac2"} layoutDependency={layoutDependency} layoutId={"P3L6aeQvK"} style={{backgroundColor: CyE7CB3gO, borderBottomLeftRadius: 17, borderBottomRightRadius: 17, borderTopLeftRadius: 17, borderTopRightRadius: 17, opacity: 0.1}} transformTemplate={transformTemplate} variants={{JRVSwvTA1: {borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, opacity: 0.2}}}/><motion.div className={"framer-7rye9z"} layoutDependency={layoutDependency} layoutId={"dzk_UUb3D"} style={{backgroundColor: CyE7CB3gO, borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200, opacity: 0.5}} transformTemplate={transformTemplate} variants={{JRVSwvTA1: {opacity: 1}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-3xtxs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3xtxs .framer-lto3rl { display: block; }", ".framer-3xtxs .framer-12clhh0 { height: 16px; overflow: visible; position: relative; width: 16px; }", ".framer-3xtxs .framer-ihsac2 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); left: 51%; overflow: visible; position: absolute; top: 51%; width: 16px; }", ".framer-3xtxs .framer-7rye9z { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 10px); left: 51%; overflow: visible; position: absolute; top: 51%; width: 10px; }", ".framer-3xtxs.framer-v-2lvr01 .framer-12clhh0 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 16px); }", ".framer-3xtxs.framer-v-2lvr01 .framer-ihsac2 { height: var(--framer-aspect-ratio-supported, 20px); width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"JRVSwvTA1":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CyE7CB3gO":"tint"}
 */
const FramerewcIV1C7u: React.ComponentType<Props> = withCSS(Component, css, "framer-3xtxs") as typeof Component;
export default FramerewcIV1C7u;

FramerewcIV1C7u.displayName = "Elements/Pulse";

FramerewcIV1C7u.defaultProps = {height: 16, width: 16};

addPropertyControls(FramerewcIV1C7u, {variant: {options: ["XyfPGA_vT", "JRVSwvTA1"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, CyE7CB3gO: {defaultValue: "var(--token-265a51b5-06ea-48da-a42e-3d51fcd0756d, rgb(0, 153, 255)) /* {\"name\":\"Blue\"} */", title: "Tint", type: ControlType.Color}} as any)

addFonts(FramerewcIV1C7u, [])